import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { FaqService } from '@app/home/components/service/faq.service';

@Pipe({
  name: 'trim',
})
@Injectable()
export class StringTrimPipe implements PipeTransform {
  constructor(private faqService: FaqService) {}

  public transform(str: string, length?: number) {
    return this.faqService.stringTrim(str, length);
  }
}
