<div class="container">
  <div class="change-password">
    <div class="card col-md-6 mx-auto">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="login-container--header">
              <h2>Manage Channel Partners</h2>
              <p>Please add/delete channel partner.</p>
            </div>
            <form (ngSubmit)="manageChannelPartners()" [formGroup]="manageChannelForm" novalidate>
              <div class="col-md-12">
                <mat-form-field appearance="fill">
                  <mat-label>Enter Channel Partner Name</mat-label>
                  <input
                    matInput
                    placeholder="Enter Channel Partner Name"
                    formControlName="channelPartnerName"
                    required
                  />
                  <mat-error
                    *ngIf="
                      !manageChannelForm.controls['channelPartnerName'].valid ||
                      !manageChannelForm.controls['channelPartnerName'].untouched
                    "
                  >
                    Channel Partner Name is required</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field appearance="fill">
                  <mat-label>Select an Action Type</mat-label>
                  <mat-select
                    [(ngModel)]="selectedValue"
                    formControlName="selectActionType"
                    (selectionChange)="getActionList($event)"
                  >
                    <mat-option *ngFor="let action of actionList" [value]="action">
                      {{ action }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      !manageChannelForm.controls['selectActionType'].valid ||
                      !manageChannelForm.controls['selectActionType'].untouched
                    "
                  >
                    Action Type is required</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <button class="btn btn-primary w-100" type="submit" [disabled]="isLoading">
                  <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
                  <span translate>Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
