import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '@app/auth';
import { AppConstants } from '@app/auth/typings/common-constants';
import { ApisService } from '../services/apis.service';
import { ChannelPartnerCommand } from '../models/common';

@Component({
  selector: 'app-manage-channels',
  templateUrl: './manage-channels.component.html',
  styleUrls: ['./manage-channels.component.scss'],
})
export class ManageChannelsComponent implements OnInit {
  isLoading: boolean;
  manageChannelForm!: FormGroup;
  actionList: any[];
  selectedValue: string;

  constructor(private formBuilder: FormBuilder, private apisService: ApisService, private titleService: Title) {
    this.isLoading = false;
    this.createForm();
    this.actionList = ['ADD', 'DELETE'];
    this.selectedValue = '';
  }

  ngOnInit(): void {
    this.titleService.setTitle('Manage Channels | ' + AppConstants.APP_TITLE);
  }

  private createForm() {
    this.manageChannelForm = this.formBuilder.group({
      channelPartnerName: ['', Validators.required],
      selectActionType: ['', Validators.required],
    });
  }

  getActionList(event: any) {
    this.selectedValue = event.value;
    // console.log(event);
  }

  manageChannelPartners() {
    this.isLoading = true;
    console.log(this.manageChannelForm);
    if (this.manageChannelForm.valid) {
      let common: ChannelPartnerCommand = {
        partner: this.manageChannelForm.value.channelPartnerName,
        type: this.manageChannelForm.value.selectActionType,
      };
      this.apisService.addOrDeleteChannelPartners(common).subscribe(
        (res: any) => {
          if (res) {
            window.location.reload();
          }
          this.isLoading = false;
        },
        (error: any) => {
          this.isLoading = false;
          console.error(error);
          if (error && error.status === 403) {
            localStorage.clear();
            window.location.reload();
          }
        }
      );
    } else {
      this.isLoading = false;
    }
  }
}
