import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService, CredentialsService } from '@app/auth';
import { AppConstants } from '@app/auth/typings/common-constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('confirmLogout') confirmLogout!: ElementRef;
  menuHidden = true;
  isLoading: boolean;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private modalService: NgbModal
  ) {
    this.isLoading = false;
  }

  ngOnInit() {}

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  changePassword() {
    this.router.navigate(['/change-password']);
  }

  confirmModal() {
    this.modalService.open(this.confirmLogout, {
      windowClass: 'confirm-logout',
      size: 'md',
      centered: true,
    });
  }

  logout() {
    this.isLoading = true;
    this.authenticationService.logout().subscribe(
      (res) => {
        if (res.status) {
          localStorage.removeItem(AppConstants.AUTH_HEADER_KEY);
          localStorage.removeItem(AppConstants.USER_DETAILS);
          this.router.navigate(['/login'], { replaceUrl: true });
        }
        this.isLoading = false;
        this.modalService.dismissAll();
      },
      (error: any) => {
        this.isLoading = false;
        this.modalService.dismissAll();
        console.error(error);
        if (error && error.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
      }
    );
  }

  get username(): string | null {
    // const credentials = this.credentialsService.credentials;
    // return credentials ? credentials.email : null;
    return this.authenticationService.getUserDetails();
  }

  isClickedMenu(event: any) {
    if (!event) {
      this.menuHidden = !event;
    }
  }
}
