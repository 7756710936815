<div class="container">
  <div class="change-password">
    <div class="card col-md-6 mx-auto">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="login-container--header">
              <h2>Update Password</h2>
              <p>Please update your password.</p>
            </div>
            <form (ngSubmit)="updatePassword()" [formGroup]="updatePasswordForm" novalidate>
              <div class="col-md-12">
                <mat-form-field appearance="fill">
                  <mat-label>Enter your Current Password</mat-label>
                  <input matInput placeholder="Enter your Current Password" formControlName="oldPassword" required />
                  <mat-error
                    *ngIf="
                      !updatePasswordForm.controls['oldPassword'].valid ||
                      !updatePasswordForm.controls['oldPassword'].untouched
                    "
                  >
                    Current Password is required</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field appearance="fill">
                  <mat-label>Enter your New Password</mat-label>
                  <input matInput placeholder="Enter your New Password" formControlName="newPassword" required />
                  <mat-error
                    *ngIf="
                      !updatePasswordForm.controls['newPassword'].valid ||
                      !updatePasswordForm.controls['newPassword'].untouched
                    "
                  >
                    New Password is required</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <button class="btn btn-primary w-100" type="submit" [disabled]="isLoading">
                  <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
                  <span translate>Update Password</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
