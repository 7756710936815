import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { AuthenticationService } from '@app/auth';
import { FaqCategory } from '../shell';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @Output() isClickedMenu = new EventEmitter<boolean>(true);

  public navItems: any[] = [
    {
      title: 'FAQ(s)',
      redirectTo: '/home',
      icon: 'home',
    },
    {
      title: 'DIY',
      redirectTo: '/diy',
      icon: 'ballot',
    },
    {
      title: 'Manage Channels',
      redirectTo: '/manage-channels',
      icon: 'business_center',
    },
    {
      title: 'Users',
      redirectTo: '/accounts',
      icon: 'supervisor_account',
    },
    // {
    //   title: 'Projects',
    //   redirectTo: '/projects',
    //   icon: 'business_center',
    // },
  ];
  public currentRoute: string = '';
  roleName!: string;
  currentLocationPath1: string = '/home?faqCategory=account_related';
  currentLocationPath2: string = '/home?faqCategory=watcho_one';
  currentLocationPath3: string = '/home?faqCategory=watcho_exclusive';
  currentLocationPath4: string = '/home?faqCategory=subscriptions';

  constructor(private router: Router, private authenticationService: AuthenticationService) {
    this.roleName = this.authenticationService.getRoleNameByToken();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.urlAfterRedirects;
        this.isClickedMenu.next(false);
      }
    });
  }

  ngOnInit(): void {}

  accoutsRelated() {
    this.router.navigate(['/home'], {
      queryParams: { faqCategory: FaqCategory[FaqCategory.ACCOUNT_RELATED].toLowerCase() },
    });
  }

  watchoOffers() {
    this.router.navigate(['/home'], {
      queryParams: { faqCategory: FaqCategory[FaqCategory.WATCHO_ONE].toLowerCase() },
    });
  }

  ottPlans() {
    this.router.navigate(['/home'], {
      queryParams: { faqCategory: FaqCategory[FaqCategory.WATCHO_EXCLUSIVE].toLowerCase() },
    });
  }

  subscriptions() {
    this.router.navigate(['/home'], {
      queryParams: { faqCategory: FaqCategory[FaqCategory.SUBSCRIPTIONS].toLowerCase() },
    });
  }
}
