import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FaqCategory } from '@app/shell/shell';
import { environment } from '@env/environment';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateFAQEntry } from '../../model/faq.';
import { FaqService } from '../../service/faq.service';

@Component({
  selector: 'app-update-text-faq',
  templateUrl: './update-text-faq.component.html',
  styleUrls: ['./update-text-faq.component.scss'],
})
export class UpdateTextFaqComponent implements OnInit {
  @Input() entryId: number = 0;
  @Output() isUpdateFaqEntry: EventEmitter<boolean> = new EventEmitter(false);

  version: string | null = environment.version;
  error: string | undefined;
  updateFAQForm!: FormGroup;
  isLoading = false;
  foundErrorLogin = false;
  editorConfig: AngularEditorConfig = {};
  htmlContent: string = '';
  faqCategory: string = FaqCategory[FaqCategory.ACCOUNT_RELATED];

  constructor(
    private formBuilder: FormBuilder,
    private faqService: FaqService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute
  ) {
    this.createForm();
    this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.faqCategory !== undefined && queryParams.faqCategory !== '') {
        if (queryParams.faqCategory.toUpperCase() === FaqCategory[FaqCategory.WATCHO_EXCLUSIVE]) {
          this.faqCategory = FaqCategory[FaqCategory.WATCHO_EXCLUSIVE];
        } else if (queryParams.faqCategory.toUpperCase() === FaqCategory[FaqCategory.WATCHO_ONE]) {
          this.faqCategory = FaqCategory[FaqCategory.WATCHO_ONE];
        } else if (queryParams.faqCategory.toUpperCase() === FaqCategory[FaqCategory.ACCOUNT_RELATED]) {
          this.faqCategory = FaqCategory[FaqCategory.ACCOUNT_RELATED];
        } else if (queryParams.faqCategory.toUpperCase() === FaqCategory[FaqCategory.SUBSCRIPTIONS]) {
          this.faqCategory = FaqCategory[FaqCategory.SUBSCRIPTIONS];
        }
      } else {
        this.faqCategory = FaqCategory[FaqCategory.ACCOUNT_RELATED];
      }
    });
  }

  ngOnInit(): void {
    this.editorConfigService();
    this.getFaqEntryDetail();
  }

  editorConfigService() {
    this.editorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '12rem',
      maxHeight: '0',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        { class: 'arial', name: 'Arial' },
        { class: 'times-new-roman', name: 'Times New Roman' },
        { class: 'calibri', name: 'Calibri' },
        { class: 'comic-sans-ms', name: 'Comic Sans MS' },
      ],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText',
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      uploadUrl: 'v1/image',
      // upload: (file: File) => { ... },
      uploadWithCredentials: false,
      sanitize: true,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [['insertImage', 'insertVideo']],
    };
  }

  getFaqEntryDetail() {
    this.isLoading = true;
    this.faqService.getFAQEntryDetail(this.entryId).subscribe(
      (res) => {
        if (res.data) {
          if (res.data.answerList.length > 0) {
            res.data.answerList.forEach((ele: any) => {
              this.htmlContent += ele + ' ';
            });
          }
          this.updateFAQForm = this.formBuilder.group({
            question: [res.data.question],
            description: [this.htmlContent],
            position: [res.data.position],
            category: [res.data.category],
          });
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        if (error && error.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
      }
    );
  }

  updateFaqEntry() {
    this.isLoading = true;
    let updateFAQEntry = new UpdateFAQEntry();
    updateFAQEntry.question = this.updateFAQForm.value.question;
    updateFAQEntry.answerList = [this.htmlContent];
    updateFAQEntry.type = 'TEXT';
    updateFAQEntry.position = +this.updateFAQForm.value.position;
    updateFAQEntry.category = this.faqCategory;
    if (this.updateFAQForm.valid && this.htmlContent.length > 0 && this.faqCategory !== '') {
      this.faqService.updateFAQEntry(this.entryId, updateFAQEntry).subscribe(
        (res) => {
          if (res) {
            this.isUpdateFaqEntry.emit(true);
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          if (error && error.status === 403) {
            localStorage.clear();
            window.location.reload();
          }
        }
      );
    } else {
      this.isLoading = false;
    }
  }

  private createForm() {
    this.updateFAQForm = this.formBuilder.group({
      question: ['', Validators.required],
      description: [this.htmlContent, Validators.required],
      position: ['', Validators.required],
      // category: [''],
    });
  }

  modalClose() {
    this.modalService.dismissAll();
  }
}
