<div class="container">
  <div class="card mx-auto">
    <div class="card-body">
      <div class="row">
        <div class="row">
          <div class="col-lg-11">
            <div class="login-container--header">
              <h2>Add New FAQ Entry</h2>
              <p>Please add new faq entry.</p>
            </div>
          </div>
          <div class="col-md-1">
            <div class="close-icon" (click)="modalClose()"><span class="material-icons"> highlight_off </span></div>
          </div>
        </div>
        <form (ngSubmit)="registerNewFAQ()" [formGroup]="registerFAQForm" novalidate>
          <!-- <div *ngIf="foundErrorLogin" class="alert alert-danger" translate>Username or password incorrect.</div> -->
          <div class="row">
            <mat-form-field appearance="fill">
              <mat-label>Enter a Question</mat-label>
              <input matInput placeholder="Enter a Question" formControlName="question" required />
              <mat-error
                *ngIf="!registerFAQForm.controls['question'].valid || !registerFAQForm.controls['question'].untouched"
              >
                question is required</mat-error
              >
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field appearance="fill">
              <mat-label>Enter Position No.</mat-label>
              <input matInput placeholder="Enter Position No." formControlName="position" required />
              <mat-error
                *ngIf="!registerFAQForm.controls['position'].valid || !registerFAQForm.controls['position'].untouched"
              >
                Position is required</mat-error
              >
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field appearance="fill">
              <mat-label>Enter a Video Url</mat-label>
              <input matInput placeholder="Enter a Video Url" formControlName="videoUrl" required />
              <mat-error
                *ngIf="!registerFAQForm.controls['videoUrl'].valid || !registerFAQForm.controls['videoUrl'].untouched"
              >
                Video Url is required</mat-error
              >
            </mat-form-field>
            <span class="notes"
              ><i
                >Note : Video Url Must Be Embed Video from Youtube Channel.
                <a href="https://support.google.com/youtube/answer/171780?hl=en" target="_blank">Click Here</a> for More
                Info</i
              ></span
            >
          </div>
          <!-- <div class="row">
              <mat-form-field appearance="fill">
                <mat-label>Enter Category Name</mat-label>
                <input matInput placeholder="Enter Category Name" formControlName="category" required />
                <mat-error
                  *ngIf="!registerFAQForm.controls['category'].valid || !registerFAQForm.controls['category'].untouched"
                >
                  Category Name is required</mat-error
                >
              </mat-form-field>
            </div> -->
          <div class="row button-align">
            <button class="btn btn-primary w-100" type="submit" [disabled]="isLoading">
              <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
              <span translate>Add New FAQ</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
