import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FaqCategory } from '@app/shell/shell';
import { environment } from '@env/environment';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegisterFAQEntry } from '../../model/faq.';
import { FaqService } from '../../service/faq.service';

@Component({
  selector: 'app-register-text-faq',
  templateUrl: './register-text-faq.component.html',
  styleUrls: ['./register-text-faq.component.scss'],
})
export class RegisterTextFaqComponent implements OnInit {
  @Output() isAddFaqEntry: EventEmitter<boolean> = new EventEmitter(false);
  version: string | null = environment.version;
  error: string | undefined;
  registerFAQForm!: FormGroup;
  isLoading = false;
  foundErrorLogin = false;
  editorConfig: AngularEditorConfig = {};
  htmlContent: string = '';
  faqCategory: string = FaqCategory[FaqCategory.ACCOUNT_RELATED];

  constructor(
    private formBuilder: FormBuilder,
    private faqService: FaqService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute
  ) {
    this.createForm();
    this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.faqCategory !== undefined && queryParams.faqCategory !== '') {
        if (queryParams.faqCategory.toUpperCase() === FaqCategory[FaqCategory.WATCHO_EXCLUSIVE]) {
          this.faqCategory = FaqCategory[FaqCategory.WATCHO_EXCLUSIVE];
        } else if (queryParams.faqCategory.toUpperCase() === FaqCategory[FaqCategory.WATCHO_ONE]) {
          this.faqCategory = FaqCategory[FaqCategory.WATCHO_ONE];
        } else if (queryParams.faqCategory.toUpperCase() === FaqCategory[FaqCategory.ACCOUNT_RELATED]) {
          this.faqCategory = FaqCategory[FaqCategory.ACCOUNT_RELATED];
        } else if (queryParams.faqCategory.toUpperCase() === FaqCategory[FaqCategory.SUBSCRIPTIONS]) {
          this.faqCategory = FaqCategory[FaqCategory.SUBSCRIPTIONS];
        }
      } else {
        this.faqCategory = FaqCategory[FaqCategory.ACCOUNT_RELATED];
      }
    });
  }

  ngOnInit(): void {
    this.editorConfigService();
  }

  editorConfigService() {
    this.editorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '12rem',
      maxHeight: '0',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        { class: 'arial', name: 'Arial' },
        { class: 'times-new-roman', name: 'Times New Roman' },
        { class: 'calibri', name: 'Calibri' },
        { class: 'comic-sans-ms', name: 'Comic Sans MS' },
      ],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText',
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      uploadUrl: 'v1/image',
      // upload: (file: File) => { ... },
      uploadWithCredentials: false,
      sanitize: true,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [['insertImage', 'insertVideo']],
    };
  }

  registerNewFAQ() {
    this.isLoading = true;
    let registerFAQEntry = new RegisterFAQEntry();
    registerFAQEntry.question = this.registerFAQForm.value.question;
    registerFAQEntry.answerList = [this.htmlContent];
    registerFAQEntry.type = 'TEXT';
    registerFAQEntry.position = +this.registerFAQForm.value.position;
    registerFAQEntry.videoUrl = '';
    registerFAQEntry.category = this.faqCategory;
    if (this.registerFAQForm.valid && this.htmlContent.length > 0 && this.faqCategory !== '') {
      this.faqService.registerFAQEntry(registerFAQEntry).subscribe(
        (res) => {
          if (res) {
            this.isAddFaqEntry.next(true);
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          if (error && error.status === 403) {
            localStorage.clear();
            window.location.reload();
          }
        }
      );
    } else {
      this.isLoading = false;
    }
  }

  private createForm() {
    this.registerFAQForm = this.formBuilder.group({
      question: ['', Validators.required],
      description: ['', Validators.required],
      position: ['', Validators.required],
      // category: ['', Validators.required],
    });
  }

  modalClose() {
    this.modalService.dismissAll();
  }
}
