import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { QuoteService } from './quote.service';
import { ActivatedRoute } from '@angular/router';
import { FaqCategory } from '@app/shell/shell';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  quote: string | undefined;
  isLoading = false;
  faqCategoryName: string = 'Account Related';

  constructor(private quoteService: QuoteService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.faqCategory !== undefined && queryParams.faqCategory !== '') {
        if (queryParams.faqCategory.toUpperCase() === FaqCategory[FaqCategory.ACCOUNT_RELATED]) {
          this.faqCategoryName = 'Account Related';
        } else if (queryParams.faqCategory.toUpperCase() === FaqCategory[FaqCategory.WATCHO_ONE]) {
          this.faqCategoryName = 'OTT Plans and Bundle';
        }
        if (queryParams.faqCategory.toUpperCase() === FaqCategory[FaqCategory.WATCHO_EXCLUSIVE]) {
          this.faqCategoryName = 'Offers Details';
        } else if (queryParams.faqCategory.toUpperCase() === FaqCategory[FaqCategory.SUBSCRIPTIONS]) {
          this.faqCategoryName = 'Subscribe, Cancel & Renew';
        }
      } else {
        this.faqCategoryName = 'Account Related';
      }
    });
  }

  ngOnInit() {
    this.isLoading = true;
    // this.quoteService
    //   .getRandomQuote({ category: 'dev' })
    //   .pipe(
    //     finalize(() => {
    //       this.isLoading = false;
    //     })
    //   )
    //   .subscribe((quote: string) => {
    //     this.quote = quote;
    //   });
  }
}
