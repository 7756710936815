import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { FaqType, Status } from '@app/auth/typings/common-constants';
import { FaqCategory } from '@app/shell/shell';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PeriodicElement } from '../model/faq.';
import { FaqService } from '../service/faq.service';
import { RegisterVideoFaqComponent } from './register-video-faq/register-video-faq.component';
import { UpdateVideoFaqComponent } from './update-video-faq/update-video-faq.component';

@Component({
  selector: 'app-portal-updates',
  templateUrl: './portal-updates.component.html',
  styleUrls: ['./portal-updates.component.scss'],
})
export class PortalUpdatesComponent implements OnInit {
  @ViewChild('conformPublishUnpublish') conformPublishUnpublish!: ElementRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ELEMENT_DATA: PeriodicElement[] = [];
  displayedColumns: string[] = ['position', 'question', 'videoUrl', 'status', 'id'];
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
  pageNumber: number = 0;
  pageSize: number = 50;
  faqContantList: any;
  isLoading = false;
  totalElements: number = 0;
  totalPages: number = 0;
  pageEvent!: PageEvent;
  entryId!: number;
  status!: boolean;
  faqCategory: string = FaqCategory[FaqCategory.ACCOUNT_RELATED];

  constructor(
    private router: Router,
    private faqService: FaqService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.faqCategory !== undefined && queryParams.faqCategory !== '') {
        if (queryParams.faqCategory.toUpperCase() === FaqCategory[FaqCategory.WATCHO_EXCLUSIVE]) {
          this.faqCategory = FaqCategory[FaqCategory.WATCHO_EXCLUSIVE];
          this.ELEMENT_DATA = [];
          this.faqEntryList();
        } else if (queryParams.faqCategory.toUpperCase() === FaqCategory[FaqCategory.WATCHO_ONE]) {
          this.faqCategory = FaqCategory[FaqCategory.WATCHO_ONE];
          this.ELEMENT_DATA = [];
          this.faqEntryList();
        } else if (queryParams.faqCategory.toUpperCase() === FaqCategory[FaqCategory.ACCOUNT_RELATED]) {
          this.faqCategory = FaqCategory[FaqCategory.ACCOUNT_RELATED];
          this.ELEMENT_DATA = [];
          this.faqEntryList();
        } else if (queryParams.faqCategory.toUpperCase() === FaqCategory[FaqCategory.SUBSCRIPTIONS]) {
          this.faqCategory = FaqCategory[FaqCategory.SUBSCRIPTIONS];
          this.ELEMENT_DATA = [];
          this.faqEntryList();
        }
      } else {
        this.faqCategory = FaqCategory[FaqCategory.ACCOUNT_RELATED];
        this.ELEMENT_DATA = [];
        this.faqEntryList();
      }
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  faqEntryList() {
    this.isLoading = true;
    // this.ELEMENT_DATA = [];
    this.totalPages = 0;
    this.faqService
      .getPaginationFAQEntryList(
        this.pageNumber,
        this.pageSize,
        Status[Status.ALL],
        FaqType[FaqType.VIDEO],
        this.faqCategory
      )
      .subscribe(
        (res) => {
          if (res.data.totalPages > 0) {
            res.data.content.forEach((element: any) => {
              this.ELEMENT_DATA.push({
                position: element.position,
                question: element.question,
                // category: element.category,
                videoUrl: element.videoUrl,
                id: element.id,
                status: element.status,
              });
            });
          }
          this.faqContantList = res.data;
          this.totalPages = this.faqContantList.totalElements; // this.ELEMENT_DATA.length;
          this.dataSource.data = this.ELEMENT_DATA;
          // this.paginatedCalculation();
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          console.log(error);
          if (error && error.status === 403) {
            localStorage.clear();
            window.location.reload();
          }
        }
      );
  }

  handlePageEvent(e: PageEvent) {
    // this.ELEMENT_DATA = [];
    // this.totalPages = [];
    this.pageEvent = e;
    // this.totalElements = e.length;
    // this.totalPages = e.length;
    // console.log(e);
    this.pageSize = e.pageSize;
    this.pageNumber = e.pageIndex;
    this.faqEntryList();
  }

  paginatedCalculation() {
    // if (this.totalElements > this.pageSize * this.pageNumber) {
    //   this.pageNumber += 1;
    //   this.totalPages.push(this.pageSize * this.pageNumber);
    //   console.log(this.totalPages);
    //   this.faqEntryList();
    // }
  }

  registerFAQ() {
    const modalRef = this.modalService.open(RegisterVideoFaqComponent, {
      windowClass: 'register-faq-entry',
      size: 'md',
    });
    modalRef.componentInstance.isAddFaqEntry.subscribe((message: boolean) => {
      if (message) {
        this.modalService.dismissAll();
        this.ELEMENT_DATA = [];
        this.faqEntryList();
      }
    });
  }

  editFaqEntry(entryId: number) {
    const modalRef = this.modalService.open(UpdateVideoFaqComponent, { windowClass: 'update-faq-entry', size: 'md' });
    modalRef.componentInstance.entryId = entryId;
    modalRef.componentInstance.isUpdateFaqEntry.subscribe((message: boolean) => {
      if (message) {
        this.modalService.dismissAll();
        this.ELEMENT_DATA = [];
        this.faqEntryList();
      }
    });
  }

  confirmModal(event: any, entryId: number, status: boolean) {
    if (status) {
      event.source.checked = true;
    } else {
      event.source.checked = false;
    }
    this.entryId = entryId;
    this.status = status ? false : true;
    this.modalService.open(this.conformPublishUnpublish, {
      windowClass: 'confirm-publish-unpublish',
      size: 'md',
      centered: true,
    });
  }

  publishUnpublishEntry() {
    this.isLoading = true;
    this.faqService.ChangeEntryStatus(this.entryId, this.status).subscribe(
      (res) => {
        if (res) {
          this.modalService.dismissAll();
          this.ELEMENT_DATA = [];
          this.faqEntryList();
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
}
