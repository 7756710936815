import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, PatternValidator } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { AuthenticationService } from './authentication.service';
import { AppConstants } from './typings/common-constants';
import { loadavg } from 'os';

const log = new Logger('Login');

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: FormGroup;
  forgotForm!: FormGroup;
  isLoading = false;
  foundErrorLogin: boolean = false;
  foundForgotMessage: boolean = false;
  hide: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService
  ) {
    this.createForm();
  }

  ngOnInit() {}

  login() {
    this.isLoading = true;
    this.foundForgotMessage = false;
    if (this.loginForm.valid) {
      this.authenticationService.login(this.loginForm.value).subscribe(
        (res) => {
          if (res.status) {
            localStorage.setItem(AppConstants.AUTH_HEADER_KEY, res.data.token);
            localStorage.setItem(AppConstants.USER_DETAILS, res.data.name);
            this.router.navigate([this.route.snapshot.queryParams['redirect'] || '/'], { replaceUrl: true });
          } else {
            this.foundErrorLogin = true;
          }
          this.isLoading = false;
        },
        (error) => {
          this.foundErrorLogin = true;
          this.isLoading = false;
        }
      );
    } else {
      this.foundErrorLogin = false;
      this.isLoading = false;
    }
  }

  forgotPasswordToggle() {
    this.foundErrorLogin = false;
    this.foundForgotMessage = true;
    setTimeout(() => {
      this.foundForgotMessage = false;
    }, 4000);
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      remember: true,
    });
  }
}
