import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from './loader/loader.component';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { StringTrimPipe } from '@app/shell/string-trim-pipe';
import { ManageChannelsComponent } from './manage-channels/manage-channels.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    MatCardModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
  ],
  declarations: [LoaderComponent, StringTrimPipe, ManageChannelsComponent],
  exports: [LoaderComponent, MatCardModule, MatTabsModule, StringTrimPipe],
})
export class SharedModule {}
