import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  isMobileView: boolean = false;
  isBrowser!: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit() {
    // if (this.isBrowser) {
    //   if (matchMedia('(max-width: 768px)').matches) {
    //     this.isMobileView = true;
    //   } else {
    //     this.isMobileView = false;
    //   }
    //   window.addEventListener(
    //     'orientationchange',
    //     () => {
    //       console.log('orientationchange');
    //       // if (window.orientation == 0 || window.orientation == 180) {
    //       // WHEN IN PORTRAIT MODE
    //       // } else {
    //       // WHEN IN LANDSCAPE MODE
    //       // }
    //       // if (matchMedia('(max-width: 768px)').matches) {
    //       //   this.isMobileView = true;
    //       // } else {
    //       //   this.isMobileView = false;
    //       // }
    //     },
    //     false
    //   );
    // }
  }
}
