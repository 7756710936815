import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { UploadResponse } from '@kolkov/angular-editor';
import { Observable, map, of, tap } from 'rxjs';

declare var AWS: any;

@Injectable({
  providedIn: 'root',
})
export class AwsService {
  constructor(private httpClient: HttpClient) {}

  public loadScript() {
    const node1 = document.createElement('script');
    node1.src = 'https://sdk.amazonaws.com/js/aws-sdk-2.224.1.min.js';

    document.getElementsByTagName('head')[0].appendChild(node1);
  }

  loadAWSConfig() {
    AWS.config.update({
      region: 'ap-south-1',
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'ap-south-1:01260455-2c09-4e26-82d3-e77dc8a78bd2',
      }),
    });
    this.initS3();
  }

  initS3() {
    new AWS.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: 'watcho-qrc' },
    });
  }

  getCloudUrl(): string {
    return environment.CLOUD_URL;
  }

  uploadPhoto(selectedFile: any, targetFolder: any) {
    return new Promise((res, rej) => {
      var file = selectedFile;
      var fileName = file.name;
      var albumPhotosKey = encodeURIComponent(targetFolder) + '/';
      var uniqueIdentifier = new Date().getTime();
      var photoKey = albumPhotosKey + uniqueIdentifier + '_' + fileName;

      // Use S3 ManagedUpload class as it supports multipart uploads
      var upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: 'watcho-qrc',
          Key: photoKey,
          Body: file,
          ContentType: 'image/png, image/jpeg, image/jpg',
        },
      });

      var promise = upload.on('httpUploadProgress', (evt: any) => {}).promise();

      promise.then(
        (data: any) => {
          res(data);
        },
        (err: any) => {
          rej(err);
        }
      );
    });
  }

  uploadPhotos = (selectedFile: File): Observable<HttpEvent<UploadResponse>> => {
    return Observable.create((observer: any) => {
      let file = selectedFile;
      let fileName = file.name;
      let albumPhotosKey = encodeURIComponent('images') + '/';
      let uniqueIdentifier = new Date().getTime();
      let photoKey = albumPhotosKey + uniqueIdentifier + '_' + fileName;

      let bucket = new AWS.S3();

      let params = {
        Bucket: 'watcho-qrc',
        Key: photoKey,
        Body: file,
        ContentType: 'image/png, image/jpeg, image/jpg',
      };

      bucket.upload(params, function (err: any, data: any) {
        if (err) {
          observer.error(err);
        }
        let responseBucketData: ResponseBucketData = {
          data: data,
          body: {
            imageUrl: environment.CLOUD_URL + data.Key,
          },
        };
        console.log(responseBucketData);
        observer.next(responseBucketData);
        observer.complete();
      });
    });
  };
}

export interface ResponseBucketData {
  data: any;
  body: {
    imageUrl: string;
  };
}
