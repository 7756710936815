import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChangePasswordCommand } from '@app/shell/shell';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';

import { Credentials, CredentialsService } from './credentials.service';
import { AppConstants } from './typings/common-constants';

export interface LoginContext {
  email: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private credentialsService: CredentialsService, private httpClient: HttpClient) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<any> {
    // Replace by proper authentication call
    const data = {
      username: context.email,
      password: context.password,
    };
    return this.httpClient.post(environment.API_BASE_URL + environment.API_VERSION + 'logindo', data).pipe((res) => {
      return res;
    });
    // this.credentialsService.setCredentials(data, context.remember);
    // return of(data);
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<any> {
    // Customize credentials invalidation here
    // this.credentialsService.setCredentials();
    // return of(true);
    let httpHeader = new HttpHeaders().set('Authorization', this.getAuthToken());
    // .set('Content-Type', 'application/json');
    return this.httpClient
      .get(environment.API_BASE_URL + environment.API_VERSION + 'logout', { headers: httpHeader })
      .pipe((res) => {
        return res;
      });
  }

  changePassword(changePasswordCommand: ChangePasswordCommand): Observable<any> {
    let httpHeader = new HttpHeaders()
      .set('Authorization', this.getAuthToken())
      .set('Content-Type', 'application/json');
    return this.httpClient
      .put(environment.API_BASE_URL + environment.API_VERSION + 'user/change-password', changePasswordCommand, {
        headers: httpHeader,
      })
      .pipe((res) => {
        return res;
      });
  }

  getAuthToken() {
    if (localStorage.getItem(AppConstants.AUTH_HEADER_KEY)) {
      let authToken: any = localStorage.getItem(AppConstants.AUTH_HEADER_KEY);
      return authToken;
    }
  }

  getRoleNameByToken() {
    let token: string = this.getAuthToken();
    let actualToken = token.split(' ');
    let tokenList = actualToken[1].split('.');
    let decriptedObject = JSON.parse(atob(tokenList[1]));
    return decriptedObject.authorities[0];
  }

  getUserDetails() {
    if (localStorage.getItem(AppConstants.USER_DETAILS)) {
      let user: any = localStorage.getItem(AppConstants.USER_DETAILS);
      return user;
    }
  }

  getUserLoginOrNot() {
    if (localStorage.getItem(AppConstants.AUTH_HEADER_KEY)) {
      return true;
    } else {
      return false;
    }
  }
}
