import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { ChangePasswordComponent } from './shell/change-password/change-password.component';
import { ManageChannelsComponent } from './@shared/manage-channels/manage-channels.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'home',
      loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    },
    {
      path: 'diy',
      loadChildren: () => import('./diy-steps/diy-steps.module').then((m) => m.DiyStepsModule),
    },
    {
      path: 'accounts',
      loadChildren: () => import('./accounts/accounts.module').then((m) => m.AccountsModule),
    },
    {
      path: 'projects',
      loadChildren: () => import('./projects/projects.module').then((m) => m.ProjectsModule),
    },
    {
      path: 'change-password',
      component: ChangePasswordComponent,
    },
    {
      path: 'manage-channels',
      component: ManageChannelsComponent,
    },
  ]),
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
