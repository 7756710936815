import { isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { AppConstants } from './auth/typings/common-constants';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class AutoConfigService {
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private router: Router, private modalService: NgbModal) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  autoLogout() {
    if (this.isBrowser) {
      if (this.getAuthorizationToken() !== '' && this.getAuthorizationToken() !== null) {
        let expireDate: any = JSON.parse(
          window.decodeURIComponent(window.atob(this.getAuthorizationToken()!.split('.')[1]))
        ).exp;
        if (expireDate * 1000 <= new Date().getTime()) {
          localStorage.clear();
          this.router.navigate(['/login'], { replaceUrl: true });
          this.modalService.dismissAll();
        }
        Promise.resolve();
      } else {
        Promise.resolve();
      }
    } else {
      Promise.resolve();
    }
  }

  getAuthorizationToken(): string | null {
    if (this.isBrowser) {
      return localStorage.getItem(AppConstants.AUTH_HEADER_KEY);
    } else {
      return null;
    }
  }
}
