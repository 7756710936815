<app-header></app-header>
<mat-sidenav-container class="side-nav">
  <mat-sidenav class="side-nav__container" mode="side" opened>
    <app-side-nav></app-side-nav>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="body-wrapper">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
