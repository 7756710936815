import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/auth';
import { AppConstants } from '@app/auth/typings/common-constants';
import { environment } from '@env/environment';
import { ChangePasswordCommand } from '../shell';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  updatePasswordForm!: FormGroup;
  isLoading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private titleService: Title
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Update Password | ' + AppConstants.APP_TITLE);
  }

  updatePassword() {
    this.isLoading = true;
    let changePasswordCommand = new ChangePasswordCommand();
    changePasswordCommand.userEmail = this.authenticationService.getUserDetails();
    changePasswordCommand.oldPassword = this.updatePasswordForm.value.oldPassword;
    changePasswordCommand.newPassword = this.updatePasswordForm.value.newPassword;
    if (this.updatePasswordForm.valid) {
      this.authenticationService.changePassword(changePasswordCommand).subscribe(
        (res: any) => {
          if (res) {
            this.isLoading = false;
            this.authenticationService.logout().subscribe((logoutRes: any) => {
              if (logoutRes.status) {
                localStorage.removeItem(AppConstants.AUTH_HEADER_KEY);
                localStorage.removeItem(AppConstants.USER_DETAILS);
                this.router.navigate(['/login'], { replaceUrl: true });
              }
            });
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    } else {
      this.isLoading = false;
    }
  }

  private createForm() {
    this.updatePasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
    });
  }
}
