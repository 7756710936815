import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChannelPartnerCommand } from '../models/common';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ApisService {
  constructor(private httpClient: HttpClient) {}

  addOrDeleteChannelPartners(command: ChannelPartnerCommand): Observable<any> {
    let params = new HttpParams().set('partner', command.partner).set('type', command.type);
    return this.httpClient
      .post(environment.API_BASE_URL + environment.API_VERSION + 'category/sync-data', null, {
        params: params,
      })
      .pipe((res) => {
        return res;
      });
  }
}
