import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from '@app/auth/typings/common-constants';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { RegisterFAQEntry, UpdateFAQEntry } from '../model/faq.';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  constructor(private httpClient: HttpClient) {}

  getAuthToken() {
    if (localStorage.getItem(AppConstants.AUTH_HEADER_KEY)) {
      let authToken: any = localStorage.getItem(AppConstants.AUTH_HEADER_KEY);
      return authToken;
    }
  }

  registerFAQEntry(registerFAQEntry: RegisterFAQEntry): Observable<any> {
    let httpHeader = new HttpHeaders()
      .set('Authorization', this.getAuthToken())
      .set('Content-Type', 'application/json');
    return this.httpClient
      .post(environment.API_BASE_URL + environment.API_VERSION + 'entry/register', registerFAQEntry, {
        headers: httpHeader,
      })
      .pipe((res) => {
        return res;
      });
  }

  updateFAQEntry(entryId: number, updateFAQEntry: UpdateFAQEntry): Observable<any> {
    let httpHeader = new HttpHeaders()
      // .set('Authorization', this.getAuthToken())
      .set('Content-Type', 'application/json');
    return this.httpClient
      .put(environment.API_BASE_URL + environment.API_VERSION + 'entry/update?entryId=' + entryId, updateFAQEntry, {
        headers: httpHeader,
      })
      .pipe((res) => {
        return res;
      });
  }

  getFAQEntryDetail(entryId: number): Observable<any> {
    return this.httpClient
      .get(environment.API_BASE_URL + environment.API_VERSION + 'entry/detail?entryId=' + entryId)
      .pipe((res) => {
        return res;
      });
  }

  getPaginationFAQEntryList(
    pageNumber: number,
    pageSize: number,
    status: string,
    type: string,
    faqCategory: string
  ): Observable<any> {
    return this.httpClient
      .get(
        environment.API_BASE_URL +
          environment.API_VERSION +
          'entry/paginated-list?pageNumber=' +
          pageNumber +
          '&pageSize=' +
          pageSize +
          '&status=' +
          status +
          '&type=' +
          type +
          '&category=' +
          faqCategory
      )
      .pipe((res) => {
        return res;
      });
  }

  getFAQEntryList(): Observable<any> {
    return this.httpClient.get(environment.API_BASE_URL + environment.API_VERSION + 'entry/listAll').pipe((res) => {
      return res;
    });
  }

  stringTrim(str: string, length?: number): string {
    if (length) {
      if (str && str.length > length) {
        return str.substr(0, length) + '...';
      } else {
        return str;
      }
    } else {
      if (str && str.length > 20) {
        return str.substr(0, 20) + '...';
      } else {
        return str;
      }
    }
  }

  ChangeEntryStatus(entryId: number, status: boolean): Observable<any> {
    let httpHeader = new HttpHeaders()
      .set('Authorization', this.getAuthToken())
      .set('Content-Type', 'application/json');
    return this.httpClient
      .put(
        environment.API_BASE_URL +
          environment.API_VERSION +
          'entry/change-status?entryId=' +
          entryId +
          '&status=' +
          status,
        null,
        { headers: httpHeader }
      )
      .pipe((res) => {
        return res;
      });
  }
}
