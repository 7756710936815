<div class="container-fluid">
  <div class="dashboard">
    <div class="row">
      <div class="col-md-12 rightSide">
        <button class="btn btn-primary" (click)="registerFAQ()">Add New Text FAQ Entry</button>
      </div>
    </div>
    <div class="row">
      <div class="mat-elevation-z8">
        <table style="table-layout: fixed" mat-table [dataSource]="dataSource">
          <!-- Position Column -->
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef>Position</th>
            <td mat-cell *matCellDef="let element">{{ element.position }}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="question">
            <th mat-header-cell *matHeaderCellDef>Question</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element.question"></td>
          </ng-container>

          <!-- Weight Column -->
          <!-- <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef>Category</th>
            <td mat-cell *matCellDef="let element">{{ element.category }}</td>
          </ng-container> -->

          <!-- Symbol Column -->
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element.description | trim: 55"></td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">{{ element.status ? 'PUBLISHED' : 'UNPUBLISHED' }}</td>
          </ng-container>

          <!-- Action Symbol Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <!-- <td mat-cell *matCellDef="let element">
              <div class="activate-action">
                <button
                  mat-raised-button
                  matTooltip="Edit Or View"
                  matTooltipClass="my-tooltip"
                  aria-label="Edit Or View"
                >
                  <span class="material-icons" (click)="editFaqEntry(element.id)"> edit </span>
                </button>
              </div>
            </td> -->
            <td mat-cell *matCellDef="let element">
              <span title="Edit Or View" class="material-icons" (click)="editFaqEntry(element.id)">edit_note</span
              >&ensp;
              <span class="slider-toggle-btn" [title]="element.status ? 'Unpublish' : 'Publish'">
                <mat-slide-toggle
                  [checked]="element.status"
                  (change)="confirmModal($event, element.id, element.status)"
                ></mat-slide-toggle>
              </span>
              <!-- <button class="btn btn-primary" (click)="editFaqEntry(element.id)">Edit Or View</button>&ensp;
              <button class="btn btn-primary" (click)="confirmModal(element.id, element.status)">
                {{ element.status ? 'Unpublish' : 'Publish' }}
              </button> -->
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" style="text-align: center" colspan="5">No data found</td>
          </tr>
        </table>
        <!-- <ng-container *ngIf="totalElements"> -->
        <mat-paginator
          [pageSizeOptions]="[50]"
          (page)="handlePageEvent($event)"
          [length]="totalPages"
          showFirstLastButtons
          aria-label="Select page"
        >
        </mat-paginator>
        <!-- </ng-container> -->
      </div>
    </div>
  </div>
</div>

<ng-template #conformPublishUnpublish let-modal>
  <div class="modal-body confirm-modalbody">
    <p>Are you sure want to {{ status ? 'publish' : 'unpublish' }} the text faq entry?</p>
  </div>
  <div class="modal-footer confirm-modalfooter">
    <button class="btn btn-pink btn-cancel" (click)="modal.dismiss('Cross click')">No</button>
    <button class="btn btn-pink" (click)="publishUnpublishEntry()" [disabled]="isLoading">
      <i class="fas fa-cog fa-spin" style="font-size: 18px" [hidden]="!isLoading"></i>{{ isLoading ? '&ensp;' : 'Yes' }}
    </button>
  </div>
</ng-template>
