export class RegisterFAQEntry {
  question: string = '';
  answerList: string[] = [];
  type: string = '';
  position: number = 0;
  category: string | null = null;
  videoUrl?: string = '';
}

export class UpdateFAQEntry {
  question: string = '';
  answerList: string[] = [];
  type: string = '';
  position: number = 0;
  category: string | null = null;
  videoUrl?: string = '';
}

export interface PeriodicElement {
  position: number;
  question: number;
  // category: string;
  description?: string;
  id: number;
  status: boolean;
  videoUrl?: string;
}
